import { default as _91id_93xjnSad0JCcMeta } from "/home/forge/merchants.smake.com/releases/v1.30.0/pages/[slug]/catalog/logos/[id].vue?macro=true";
import { default as indexEeZxwbp968Meta } from "/home/forge/merchants.smake.com/releases/v1.30.0/pages/[slug]/catalog/logos/index.vue?macro=true";
import { default as _91id_931WWCmxjB7FMeta } from "/home/forge/merchants.smake.com/releases/v1.30.0/pages/[slug]/customers/[customer]/logos/[id].vue?macro=true";
import { default as indexXvnk7tjYepMeta } from "/home/forge/merchants.smake.com/releases/v1.30.0/pages/[slug]/customers/[customer]/logos/index.vue?macro=true";
import { default as colorsk1Elyxu7SNMeta } from "/home/forge/merchants.smake.com/releases/v1.30.0/pages/[slug]/customers/[id]/colors.vue?macro=true";
import { default as favorite_45variantseomY7gPDOhMeta } from "/home/forge/merchants.smake.com/releases/v1.30.0/pages/[slug]/customers/[id]/favorite-variants.vue?macro=true";
import { default as indexdrWrrVAP1hMeta } from "/home/forge/merchants.smake.com/releases/v1.30.0/pages/[slug]/customers/[id]/index.vue?macro=true";
import { default as index1IJpEUl1i8Meta } from "/home/forge/merchants.smake.com/releases/v1.30.0/pages/[slug]/customers/index.vue?macro=true";
import { default as dashboardI93xLpT6pCMeta } from "/home/forge/merchants.smake.com/releases/v1.30.0/pages/[slug]/dashboard.vue?macro=true";
import { default as entranceD7QEGxCUYQMeta } from "/home/forge/merchants.smake.com/releases/v1.30.0/pages/[slug]/entrance.vue?macro=true";
import { default as index962LwOICQCMeta } from "/home/forge/merchants.smake.com/releases/v1.30.0/pages/[slug]/index.vue?macro=true";
import { default as loginJhllnap9CkMeta } from "/home/forge/merchants.smake.com/releases/v1.30.0/pages/[slug]/login.vue?macro=true";
import { default as indexr1m1gVsWV9Meta } from "/home/forge/merchants.smake.com/releases/v1.30.0/pages/index.vue?macro=true";
export default [
  {
    name: "slug-catalog-logos-id",
    path: "/:slug()/catalog/logos/:id()",
    meta: _91id_93xjnSad0JCcMeta || {},
    component: () => import("/home/forge/merchants.smake.com/releases/v1.30.0/pages/[slug]/catalog/logos/[id].vue").then(m => m.default || m)
  },
  {
    name: indexEeZxwbp968Meta?.name ?? "slug-catalog-logos",
    path: "/:slug()/catalog/logos",
    meta: indexEeZxwbp968Meta || {},
    component: () => import("/home/forge/merchants.smake.com/releases/v1.30.0/pages/[slug]/catalog/logos/index.vue").then(m => m.default || m)
  },
  {
    name: "slug-customers-customer-logos-id",
    path: "/:slug()/customers/:customer()/logos/:id()",
    meta: _91id_931WWCmxjB7FMeta || {},
    component: () => import("/home/forge/merchants.smake.com/releases/v1.30.0/pages/[slug]/customers/[customer]/logos/[id].vue").then(m => m.default || m)
  },
  {
    name: "slug-customers-customer-logos",
    path: "/:slug()/customers/:customer()/logos",
    meta: indexXvnk7tjYepMeta || {},
    component: () => import("/home/forge/merchants.smake.com/releases/v1.30.0/pages/[slug]/customers/[customer]/logos/index.vue").then(m => m.default || m)
  },
  {
    name: "slug-customers-id-colors",
    path: "/:slug()/customers/:id()/colors",
    meta: colorsk1Elyxu7SNMeta || {},
    component: () => import("/home/forge/merchants.smake.com/releases/v1.30.0/pages/[slug]/customers/[id]/colors.vue").then(m => m.default || m)
  },
  {
    name: "slug-customers-id-favorite-variants",
    path: "/:slug()/customers/:id()/favorite-variants",
    meta: favorite_45variantseomY7gPDOhMeta || {},
    component: () => import("/home/forge/merchants.smake.com/releases/v1.30.0/pages/[slug]/customers/[id]/favorite-variants.vue").then(m => m.default || m)
  },
  {
    name: "slug-customers-id",
    path: "/:slug()/customers/:id()",
    meta: indexdrWrrVAP1hMeta || {},
    component: () => import("/home/forge/merchants.smake.com/releases/v1.30.0/pages/[slug]/customers/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index1IJpEUl1i8Meta?.name ?? "slug-customers",
    path: "/:slug()/customers",
    meta: index1IJpEUl1i8Meta || {},
    component: () => import("/home/forge/merchants.smake.com/releases/v1.30.0/pages/[slug]/customers/index.vue").then(m => m.default || m)
  },
  {
    name: dashboardI93xLpT6pCMeta?.name ?? "slug-dashboard",
    path: "/:slug()/dashboard",
    meta: dashboardI93xLpT6pCMeta || {},
    component: () => import("/home/forge/merchants.smake.com/releases/v1.30.0/pages/[slug]/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "slug-entrance",
    path: "/:slug()/entrance",
    meta: entranceD7QEGxCUYQMeta || {},
    component: () => import("/home/forge/merchants.smake.com/releases/v1.30.0/pages/[slug]/entrance.vue").then(m => m.default || m)
  },
  {
    name: "slug",
    path: "/:slug()",
    component: () => import("/home/forge/merchants.smake.com/releases/v1.30.0/pages/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: loginJhllnap9CkMeta?.name ?? "slug-login",
    path: "/:slug()/login",
    meta: loginJhllnap9CkMeta || {},
    component: () => import("/home/forge/merchants.smake.com/releases/v1.30.0/pages/[slug]/login.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/forge/merchants.smake.com/releases/v1.30.0/pages/index.vue").then(m => m.default || m)
  }
]